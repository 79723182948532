<template>
  <div class="row">
    <retry-notification :show="error" @retry="updateData()" />
    <div class="flex xs12">
      <va-card :title="$t('users.edit', { name: user.name })">
        <actions
          slot="actions"
          crud-links="profile"
          controller="Users"
          :actions="actions"
          :action-data="{ id: currentUser.id }"
        />
        <tabs-container :tabs="tabs" @change="onTabChange">
          <template v-slot:data>
            <data-form :user="user" :loading="loading" @submit="submit" />
          </template>
          <template v-slot:password>
            <change-password
              :user="user"
              :loading="loading"
              @submit="changePassword"
            />
          </template>
          <template v-slot:avatar>
            <change-avatar
              ref="changeAvatar"
              :user="user"
              :loading="loading"
              @submit="changeAvatar"
            />
          </template>
          <template v-slot:groups>
            <change-group
              :user="user"
              :loading="loading"
              @submit="changeGroup"
            />
          </template>
          <template v-slot:dcpi>
            <change-role :user="user" :loading="loading" @submit="changeRole" />
          </template>
        </tabs-container>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const DataForm = () => import(/* webpackPrefetch: true */ "../Form");
const ChangeRole = () => import(/* webpackPrefetch: true */ "../ChangeRole");
const ChangeGroup = () => import(/* webpackPrefetch: true */ "../ChangeGroup");
const ChangePassword = () =>
  import(/* webpackPrefetch: true */ "../ChangePassword");
const ChangeAvatar = () =>
  import(/* webpackPrefetch: true */ "../ChangeAvatar");
const Actions = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Actions");
const TabsContainer = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/TabsContainer");

export default {
  name: "profile-edit",
  components: {
    DataForm,
    Actions,
    ChangeRole,
    ChangeGroup,
    ChangeAvatar,
    ChangePassword,
    TabsContainer,
  },
  data() {
    return {
      error: false,
      loading: false,
      actions: ["view"],
      user: { id: 0, name: "" },
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    tabs() {
      const t = [
        { title: this.$t("users.tabs.data.title"), name: "data" },
        { title: this.$t("users.tabs.password.title"), name: "password" },
        { title: this.$t("users.tabs.avatar.title"), name: "avatar" },
      ];

      if (this.currentUser.all("Users", ["updateGroups", "groups"])) {
        t.push({ title: this.$t("users.tabs.groups.title"), name: "groups" });
      }
      if (this.currentUser.can("Users", "roles")) {
        t.push({ title: this.$t("users.tabs.dcpi.title"), name: "dcpi" });
      }

      return t;
    },
  },
  watch: {
    currentUser(val) {
      this.updateData(val.id);
    },
  },
  created() {
    if (!this.currentUser) return;
    this.updateData(this.currentUser.id);
  },
  methods: {
    routeBuilder(id) {
      return `users/${id}`;
    },
    onTabChange(tab) {
      if (tab.name === "avatar") {
        this.$nextTick(() => {
          this.$refs.changeAvatar.$refs.avatar.onResize();
        });
      }
    },
    async updateData(userId) {
      this.loading = true;
      this.error = false;

      let u = false;
      try {
        u = await this.$http.get(this.routeBuilder(userId));
      } catch (err) {
        // console.log('Error fetching user data', err)
        this.error = true;
        this.loading = false;
        return;
      }

      this.loading = false;
      this.user = u.data.data;
    },

    async submit(user) {
      this.loading = true;

      try {
        await this.$http.put(this.routeBuilder(user.id), user);
      } catch (e) {
        // console.log('Error while updating user', e)
        this.loading = false;
        return;
      }

      this.loading = false;
      this.showToast(this.$t("notifications.alerts.success"), {
        icon: "fa-check",
        position: "top-right",
      });
    },
    changePassword(user) {
      this.submit(user);
    },
    async changeAvatar({ user, image }) {
      this.loading = true;

      try {
        await this.$http.post(`users/avatar/${user.id}`, image);
      } catch (e) {
        // console.log('Error while updating user', e)
        this.loading = false;
        return;
      }

      this.loading = false;
      this.showToast(this.$t("notifications.alerts.success"), {
        icon: "fa-check",
        position: "top-right",
      });
    },
    changeRole(user) {
      this.submit(user);
    },
    async changeGroup(user) {
      this.loading = true;

      try {
        await this.$http.put("users/groups/" + user.id, user);
      } catch (e) {
        // console.log('Error while updating user', e)
        this.loading = false;
        return;
      }

      this.loading = false;
      this.showToast(this.$t("notifications.alerts.success"), {
        icon: "fa-check",
        position: "top-right",
      });
      this.$router.push({ name: "usersIndex" });
    },
  },
};
</script>
